import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import CustomerUptimeApi from '../generated/api/CustomerUptime';

export default (service: Servicelayer) => ({
  ...CustomerUptimeApi(service),

  // Add custom calls here

});
