

















import Vue from 'vue';

export default Vue.extend({
  name: 'LayoutExterior',
  data: () => ({
    theme: 'glittr-pixel-light',
  }),
});
