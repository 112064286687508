// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetCustomerUptimeStatusByNameRequestViewModel from '../../viewModel/request/CustomerUptimeStatus/GetCustomerUptimeStatusByNameRequestViewModel';
import Int64LookupResourceListResultViewModel from '../../viewModel/resource/Int64LookupResourceListResultViewModel';
import CustomerUptimeStatusResourceViewModel from '../../viewModel/resource/CustomerUptimeStatusResourceViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getLookupCustomerUptimeStatus(config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-lookup-customer-uptime-status'] ?? 'CustomerUptimeStatus/Lookup';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new Int64LookupResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async getCustomerUptimeStatusByName(request: GetCustomerUptimeStatusByNameRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-customer-uptime-status-by-name'] ?? 'CustomerUptimeStatus/GetByName';
    const response = await service.get<any>(endpointPath, {
      query: {
        statusName: requestDTO.statusName,
      },
      ...config,
    });
    const dto = response.data.result;
    return new CustomerUptimeStatusResourceViewModel().fromDTO(dto);
  },
});
