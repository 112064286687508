import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import CustomerTransactionInvoicingTypeApi from '../generated/api/CustomerTransactionInvoicingType';

export default (service: Servicelayer) => ({
  ...CustomerTransactionInvoicingTypeApi(service),

  // Add custom calls here

});
