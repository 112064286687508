// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetPagedInfoMessageListRequestViewModel from '../../viewModel/request/InfoMessage/GetPagedInfoMessageListRequestViewModel';
import InfoMessageListResourcePagingResultViewModel from '../../viewModel/resource/InfoMessageListResourcePagingResultViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getPagedInfoMessageList(request: GetPagedInfoMessageListRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-paged-info-message-list'] ?? 'InfoMessage';
    const response = await service.get<any>(endpointPath, {
      query: {
        customerInstallationId: requestDTO.customerInstallationId,
        messageType: requestDTO.messageType,
        messageAbout: requestDTO.messageAbout,
        time: requestDTO.time,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new InfoMessageListResourcePagingResultViewModel().fromDTO(dto);
  },
});
