// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import CustomerUptimeResourceViewModel from '../../../../viewModel/resource/CustomerUptimeResourceViewModel';
import CustomerUptimeResourceDTO from '../../dto/resource/CustomerUptimeResourceDTO';

export default abstract class CustomerUptimeResourceModel extends BaseModel<CustomerUptimeResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get creatorId() { return this.dto.creatorId; }
  set creatorId(value) { this.dto.creatorId = value; }
  /**
  */
  get creatorName() { return this.dto.creatorName; }
  set creatorName(value) { this.dto.creatorName = value; }
  /**
  * @type {date-time}
  */
  get created() { return this.dto.created; }
  set created(value) { this.dto.created = value; }
  /**
  * @type {int64}
  */
  get modifierId() { return this.dto.modifierId; }
  set modifierId(value) { this.dto.modifierId = value; }
  /**
  */
  get modifierName() { return this.dto.modifierName; }
  set modifierName(value) { this.dto.modifierName = value; }
  /**
  * @type {date-time}
  */
  get modified() { return this.dto.modified; }
  set modified(value) { this.dto.modified = value; }
  /**
  * @type {int64}
  */
  get customerInstallationId() { return this.dto.customerInstallationId; }
  set customerInstallationId(value) { this.dto.customerInstallationId = value; }
  /**
  * @type {int64}
  */
  get customerUptimeStatusId() { return this.dto.customerUptimeStatusId; }
  set customerUptimeStatusId(value) { this.dto.customerUptimeStatusId = value; }
  /**
  */
  get uptimeMessage() { return this.dto.uptimeMessage; }
  set uptimeMessage(value) { this.dto.uptimeMessage = value; }
  /**
  * @type {date-time}
  */
  get requestTime() { return this.dto.requestTime; }
  set requestTime(value) { this.dto.requestTime = value; }
  /**
  * @type {int64}
  */
  get statusGroup() { return this.dto.statusGroup; }
  set statusGroup(value) { this.dto.statusGroup = value; }
  /**
  * @type {int32}
  */
  get httpStatusCode() { return this.dto.httpStatusCode; }
  set httpStatusCode(value) { this.dto.httpStatusCode = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.creatorid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.creatorId = value;
    this.dto.creatorName = qs.creatorname;
    this.dto.created = qs.created;
    value = Number.parseFloat(qs.modifierid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.modifierId = value;
    this.dto.modifierName = qs.modifiername;
    this.dto.modified = qs.modified;
    value = Number.parseFloat(qs.customerinstallationid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.customerInstallationId = value;
    value = Number.parseFloat(qs.customeruptimestatusid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.customerUptimeStatusId = value;
    this.dto.uptimeMessage = qs.uptimemessage;
    this.dto.requestTime = qs.requesttime;
    value = Number.parseFloat(qs.statusgroup);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.statusGroup = value;
    value = Number.parseFloat(qs.httpstatuscode);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.httpStatusCode = value;
  }
}
