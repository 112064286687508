// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import CustomerInstallationListResourceViewModel from '../../../../viewModel/resource/CustomerInstallationListResourceViewModel';
import CustomerInstallationListResourceDTO from '../../dto/resource/CustomerInstallationListResourceDTO';

export default abstract class CustomerInstallationListResourceModel extends BaseModel<CustomerInstallationListResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get creatorId() { return this.dto.creatorId; }
  set creatorId(value) { this.dto.creatorId = value; }
  /**
  */
  get creatorName() { return this.dto.creatorName; }
  set creatorName(value) { this.dto.creatorName = value; }
  /**
  * @type {date-time}
  */
  get created() { return this.dto.created; }
  set created(value) { this.dto.created = value; }
  /**
  * @type {int64}
  */
  get modifierId() { return this.dto.modifierId; }
  set modifierId(value) { this.dto.modifierId = value; }
  /**
  */
  get modifierName() { return this.dto.modifierName; }
  set modifierName(value) { this.dto.modifierName = value; }
  /**
  * @type {date-time}
  */
  get modified() { return this.dto.modified; }
  set modified(value) { this.dto.modified = value; }
  /**
  */
  get customerName() { return this.dto.customerName; }
  set customerName(value) { this.dto.customerName = value; }
  /**
  */
  get ilWebApiURL() { return this.dto.ilWebApiURL; }
  set ilWebApiURL(value) { this.dto.ilWebApiURL = value; }
  /**
  */
  get ilFrontendURL() { return this.dto.ilFrontendURL; }
  set ilFrontendURL(value) { this.dto.ilFrontendURL = value; }
  /**
  * @type {int32}
  */
  get transactionOfCurrentMonth() { return this.dto.transactionOfCurrentMonth; }
  set transactionOfCurrentMonth(value) { this.dto.transactionOfCurrentMonth = value; }
  /**
  * @type {int32}
  */
  get transactionOfPreviousMonth() { return this.dto.transactionOfPreviousMonth; }
  set transactionOfPreviousMonth(value) { this.dto.transactionOfPreviousMonth = value; }
  /**
  * @type {date-time}
  */
  get activeFrom() { return this.dto.activeFrom; }
  set activeFrom(value) { this.dto.activeFrom = value; }
  /**
  * @type {date-time}
  */
  get activeTo() { return this.dto.activeTo; }
  set activeTo(value) { this.dto.activeTo = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.creatorid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.creatorId = value;
    this.dto.creatorName = qs.creatorname;
    this.dto.created = qs.created;
    value = Number.parseFloat(qs.modifierid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.modifierId = value;
    this.dto.modifierName = qs.modifiername;
    this.dto.modified = qs.modified;
    this.dto.customerName = qs.customername;
    this.dto.ilWebApiURL = qs.ilwebapiurl;
    this.dto.ilFrontendURL = qs.ilfrontendurl;
    value = Number.parseFloat(qs.transactionofcurrentmonth);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.transactionOfCurrentMonth = value;
    value = Number.parseFloat(qs.transactionofpreviousmonth);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.transactionOfPreviousMonth = value;
    this.dto.activeFrom = qs.activefrom;
    this.dto.activeTo = qs.activeto;
  }
}
