// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetPagedCustomerTransactionListRequestViewModel from '../../viewModel/request/CustomerTransaction/GetPagedCustomerTransactionListRequestViewModel';
import GetCustomerTransactionDetailPageUrlRequestViewModel from '../../viewModel/request/CustomerTransaction/GetCustomerTransactionDetailPageUrlRequestViewModel';
import DownloadCustomerTransactionReportRequestViewModel from '../../viewModel/request/CustomerTransaction/DownloadCustomerTransactionReportRequestViewModel';
import CustomerTransactionListResourcePagingResultViewModel from '../../viewModel/resource/CustomerTransactionListResourcePagingResultViewModel';
import Int64LookupResourceListResultViewModel from '../../viewModel/resource/Int64LookupResourceListResultViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getPagedCustomerTransactionList(request: GetPagedCustomerTransactionListRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-paged-customer-transaction-list'] ?? 'CustomerTransaction';
    const response = await service.get<any>(endpointPath, {
      query: {
        customerInstallationId: requestDTO.customerInstallationId,
        transactionStatus: requestDTO.transactionStatus,
        dateFrom: requestDTO.dateFrom,
        dateTo: requestDTO.dateTo,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new CustomerTransactionListResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async getLookupTransactionStatus(config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-lookup-transaction-status'] ?? 'CustomerTransaction/LookupStatus';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new Int64LookupResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async getCustomerTransactionDetailPageUrl(request: GetCustomerTransactionDetailPageUrlRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-customer-transaction-detail-page-url'] ?? 'CustomerTransaction/GetCustomerTransactionDetailPageUrl';
    const response = await service.get<any>(endpointPath, {
      query: {
        integrationCallId: requestDTO.integrationCallId,
        customerName: requestDTO.customerName,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as string | undefined;
  },
  /**  */
  async downloadCustomerTransactionReport(request: DownloadCustomerTransactionReportRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-download-customer-transaction-report'] ?? 'CustomerTransaction/DownloadCustomerTransactionReport';
    const response = await service.get<any>(endpointPath, {
      responseType: 'blob',
      query: {
        dateFrom: requestDTO.dateFrom,
        dateTo: requestDTO.dateTo,
        customerInstallationId: requestDTO.customerInstallationId,
        transactionStatus: requestDTO.transactionStatus,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return new Blob([result], { type: 'application/octet-stream' });
  },
});
