// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import InfoMessageListResourceViewModel from '../../../../viewModel/resource/InfoMessageListResourceViewModel';
import InfoMessageListResourceDTO from '../../dto/resource/InfoMessageListResourceDTO';

export default abstract class InfoMessageListResourceModel extends BaseModel<InfoMessageListResourceDTO> {
  /**
  * @type {int64}
  */
  get customerInstallationId() { return this.dto.customerInstallationId; }
  set customerInstallationId(value) { this.dto.customerInstallationId = value; }
  /**
  */
  get customerName() { return this.dto.customerName; }
  set customerName(value) { this.dto.customerName = value; }
  /**
  */
  get translationKey() { return this.dto.translationKey; }
  set translationKey(value) { this.dto.translationKey = value; }
  /**
  */
  get translationArgs() { return this.dto.translationArgs; }
  set translationArgs(value) { this.dto.translationArgs = value; }
  /**
  * @type {int32}
  */
  get messageType() { return this.dto.messageType; }
  set messageType(value) { this.dto.messageType = value; }
  /**
  * @type {int32}
  */
  get messageAbout() { return this.dto.messageAbout; }
  set messageAbout(value) { this.dto.messageAbout = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.customerinstallationid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.customerInstallationId = value;
    this.dto.customerName = qs.customername;
    this.dto.translationKey = qs.translationkey;
    value = Number.parseFloat(qs.messagetype);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.messageType = value;
    value = Number.parseFloat(qs.messageabout);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.messageAbout = value;
  }
}
