






import Vue from 'vue';
import LayoutDefault from './layout-default.vue';

export default Vue.extend({
  auth: true,
  components: {
    LayoutDefault,
  },
  props: {
    hasBackButton: { type: Boolean, default: false },
  },
});
