// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetPagedCustomerUptimeListRequestViewModel from '../../viewModel/request/CustomerUptime/GetPagedCustomerUptimeListRequestViewModel';
import GetCurrentCustomerUptimeByCustomerIdRequestViewModel from '../../viewModel/request/CustomerUptime/GetCurrentCustomerUptimeByCustomerIdRequestViewModel';
import UpdateCustomerUptimeMaintenanceStatusRequestViewModel from '../../viewModel/request/CustomerUptime/UpdateCustomerUptimeMaintenanceStatusRequestViewModel';
import ImportCustomerUptimeRequestViewModel from '../../viewModel/request/CustomerUptime/ImportCustomerUptimeRequestViewModel';
import RequestCustomerUptimeRequestViewModel from '../../viewModel/request/CustomerUptime/RequestCustomerUptimeRequestViewModel';
import CustomerUptimeListResourcePagingResultViewModel from '../../viewModel/resource/CustomerUptimeListResourcePagingResultViewModel';
import CustomerUptimeListResourceListResultViewModel from '../../viewModel/resource/CustomerUptimeListResourceListResultViewModel';
import CustomerUptimeResourceViewModel from '../../viewModel/resource/CustomerUptimeResourceViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getPagedCustomerUptimeList(request: GetPagedCustomerUptimeListRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-paged-customer-uptime-list'] ?? 'CustomerUptime';
    const response = await service.get<any>(endpointPath, {
      query: {
        textSearchFields: requestDTO.textSearchFields,
        customerInstallationId: requestDTO.customerInstallationId,
        customerUptimeStatusId: requestDTO.customerUptimeStatusId,
        dateFrom: requestDTO.dateFrom,
        dateTo: requestDTO.dateTo,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new CustomerUptimeListResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async getCurrentCustomerUptimeByCustomerId(request: GetCurrentCustomerUptimeByCustomerIdRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-current-customer-uptime-by-customer-id'] ?? 'CustomerUptime/GetByCustomerId';
    const response = await service.get<any>(endpointPath, {
      query: {
        customerId: requestDTO.customerId,
      },
      ...config,
    });
    const dto = response.data.result;
    return new CustomerUptimeListResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async updateCustomerUptimeMaintenanceStatus(request: UpdateCustomerUptimeMaintenanceStatusRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-customer-uptime-maintenance-status'] ?? 'CustomerUptime/Maintenance';
    const response = await service.put<any>(endpointPath, {
      query: {
        customerId: requestDTO.customerId,
        enableMaintenance: requestDTO.enableMaintenance,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async importCustomerUptimes(config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-import-customer-uptimes'] ?? 'CustomerUptime/ImportCustomerUptimes';
    const response = await service.post<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async importCustomerUptime(request: ImportCustomerUptimeRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-import-customer-uptime'] ?? 'CustomerUptime/ImportCustomerUptime';
    const response = await service.post<any>(endpointPath, {
      query: {
        customerId: requestDTO.customerId,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async requestCustomerUptime(request: RequestCustomerUptimeRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-request-customer-uptime'] ?? 'CustomerUptime/RequestCustomerUptime';
    const response = await service.get<any>(endpointPath, {
      query: {
        customerId: requestDTO.customerId,
      },
      ...config,
    });
    const dto = response.data.result;
    return new CustomerUptimeResourceViewModel().fromDTO(dto);
  },
});
