import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';
import _ from '@glittr/frontend-core/src/utils';
import CustomerInstallationResourceModel from '../../generated/types/model/resource/CustomerInstallationResourceModel';

export default class CustomerInstallationResourceViewModel extends CustomerInstallationResourceModel {
  // For instructions on how to use ViewModels visit:
  // https://glittrsoftware.visualstudio.com/glittr.core/_wiki/wikis/glittr.core.wiki/155/ViewModels-Models-DTOs

  get hasCustomerInstallationId() { return !_.isEmpty((this.dto as any).id); }
  set hasCustomerInstallationId(value) { (this.dto as any).hasCustomerInstallationId = value; }

  schema = {
    ...queryBuilder.properties({
      customerName: { required: true, type: 'string' },
      ilWebApiURL: { required: true, type: 'string' },
      userName: { required: true, type: 'string' },
      password: { type: 'string' },
      activeFrom: { required: true, type: 'string', pattern: validationPatterns.date },
    }),
    ...queryBuilder.if({ hasCustomerInstallationId: false }),
    ...queryBuilder.thenRequired([
      'password',
    ]),
  };
}
