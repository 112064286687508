import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import CustomerInstallationApi from '../generated/api/CustomerInstallation';

export default (service: Servicelayer) => ({
  ...CustomerInstallationApi(service),

  // Add custom calls here

});
