import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import CustomerUptimeStatusApi from '../generated/api/CustomerUptimeStatus';

export default (service: Servicelayer) => ({
  ...CustomerUptimeStatusApi(service),

  // Add custom calls here

});
