




















































































































































import Vue from 'vue';

export default Vue.extend({
  auth: true,
  data: () => ({
    profileMenuVisible: false,
    date: Vue.$date.now().format('YYYY-MM-DD'),
    startOfCurrentMonth: Vue.$date.now().format('YYYY-MM-01T00:00:00.000'),
    dateTo: Vue.$date.now().format('YYYY-MM-DDT23:59:00.000'),
    isSidedrawerVisible: Vue.$media.isNotMobile,
    isSettingsVisible: false,
    isNavMinifed: false,
  }),
  computed: {
    isAdmin(): boolean {
      let isAdmin = false;
      if (this.$auth.isLoggedIn) {
        isAdmin = this.$auth.isInRole('Admin', 'HostAdmin');
      }
      return isAdmin;
    },
  },
  watch: {
    isNavMinifed() {
      this.$localStorage.set('isNavMinifed', this.isNavMinifed);
    },
  },
  beforeMount() {
    if (this.$media.isNotMobile) {
      this.isSidedrawerVisible = this.$localStorage.get<boolean>('isSidedrawerVisible') ?? true;
      this.isSettingsVisible = this.isAdmin && (this.$localStorage.get<boolean>('isSettingsVisible') ?? false);
      this.isNavMinifed = this.$localStorage.get<boolean>('isNavMinifed') ?? false;
      this.$nextTick(() => {
        this.isNavMinifed = this.$localStorage.get<boolean>('isNavMinifed') ?? false;
      });
    }
  },
  methods: {
    navigationClicked() {
      if (this.$media.isMobile) {
        this.toggleSideDrawerVisible(false);
      }
    },
    settingsClicked() {
      if (this.$media.isMobile) {
        this.toggleSettings();
      }
    },
    async doLogoff() {
      try {
        await this.$auth.logout();
        this.$router.push('/_exterior/login');
      } catch (error) {
        this.$log.error('Unable to log out an error occurred');
        this.$log.error(error);
      }
    },
    toggleSettings() {
      this.isSettingsVisible = !this.isSettingsVisible;
      this.$localStorage.set('isSettingsVisible', this.isSettingsVisible);
      if (this.$media.isMobile) {
        this.isSidedrawerVisible = false;
      } else {
        this.isNavMinifed = this.isSettingsVisible;
      }
    },
    toggleSideDrawerVisible(isVisible?: boolean) {
      const newVisibleState = isVisible ?? !this.isSidedrawerVisible;
      this.isSidedrawerVisible = newVisibleState;
      this.$localStorage.set('isSidedrawerVisible', newVisibleState);
    },
  },
});
