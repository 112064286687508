// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetPagedCustomerInstallationListRequestViewModel from '../../viewModel/request/CustomerInstallation/GetPagedCustomerInstallationListRequestViewModel';
import CustomerInstallationListResourcePagingResultViewModel from '../../viewModel/resource/CustomerInstallationListResourcePagingResultViewModel';
import CustomerInstallationResourceViewModel from '../../viewModel/resource/CustomerInstallationResourceViewModel';
import Int64LookupResourceListResultViewModel from '../../viewModel/resource/Int64LookupResourceListResultViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getPagedCustomerInstallationList(request: GetPagedCustomerInstallationListRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-paged-customer-installation-list'] ?? 'CustomerInstallation';
    const response = await service.get<any>(endpointPath, {
      query: {
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new CustomerInstallationListResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async createCustomerInstallation(model: CustomerInstallationResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-customer-installation'] ?? 'CustomerInstallation';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async getCustomerInstallationById(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-customer-installation-by-id'] ?? 'CustomerInstallation/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new CustomerInstallationResourceViewModel().fromDTO(dto);
  },
  /**  */
  async updateCustomerInstallation(id: number, model: CustomerInstallationResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-customer-installation'] ?? 'CustomerInstallation/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.put<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async deleteCustomerInstallation(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-customer-installation'] ?? 'CustomerInstallation/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async getLookupCustomerInstallation(config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-lookup-customer-installation'] ?? 'CustomerInstallation/Lookup';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new Int64LookupResourceListResultViewModel().fromDTO(dto);
  },
});
