// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import CustomerTransactionListResourceViewModel from '../../../../viewModel/resource/CustomerTransactionListResourceViewModel';
import CustomerTransactionListResourceDTO from '../../dto/resource/CustomerTransactionListResourceDTO';

export default abstract class CustomerTransactionListResourceModel extends BaseModel<CustomerTransactionListResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get creatorId() { return this.dto.creatorId; }
  set creatorId(value) { this.dto.creatorId = value; }
  /**
  */
  get creatorName() { return this.dto.creatorName; }
  set creatorName(value) { this.dto.creatorName = value; }
  /**
  * @type {date-time}
  */
  get created() { return this.dto.created; }
  set created(value) { this.dto.created = value; }
  /**
  * @type {int64}
  */
  get modifierId() { return this.dto.modifierId; }
  set modifierId(value) { this.dto.modifierId = value; }
  /**
  */
  get modifierName() { return this.dto.modifierName; }
  set modifierName(value) { this.dto.modifierName = value; }
  /**
  * @type {date-time}
  */
  get modified() { return this.dto.modified; }
  set modified(value) { this.dto.modified = value; }
  /**
  * @type {int64}
  */
  get customerInstallationId() { return this.dto.customerInstallationId; }
  set customerInstallationId(value) { this.dto.customerInstallationId = value; }
  /**
  */
  get customerName() { return this.dto.customerName; }
  set customerName(value) { this.dto.customerName = value; }
  /**
  * @type {int64}
  */
  get integrationCallId() { return this.dto.integrationCallId; }
  set integrationCallId(value) { this.dto.integrationCallId = value; }
  /**
  */
  get status() { return this.dto.status; }
  set status(value) { this.dto.status = value; }
  /**
  * @type {date-time}
  */
  get entryDate() { return this.dto.entryDate; }
  set entryDate(value) { this.dto.entryDate = value; }
  /**
  */
  get taskName() { return this.dto.taskName; }
  set taskName(value) { this.dto.taskName = value; }
  /**
  */
  get appSystemName() { return this.dto.appSystemName; }
  set appSystemName(value) { this.dto.appSystemName = value; }
  /**
  */
  get statusMessage() { return this.dto.statusMessage; }
  set statusMessage(value) { this.dto.statusMessage = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.creatorid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.creatorId = value;
    this.dto.creatorName = qs.creatorname;
    this.dto.created = qs.created;
    value = Number.parseFloat(qs.modifierid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.modifierId = value;
    this.dto.modifierName = qs.modifiername;
    this.dto.modified = qs.modified;
    value = Number.parseFloat(qs.customerinstallationid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.customerInstallationId = value;
    this.dto.customerName = qs.customername;
    value = Number.parseFloat(qs.integrationcallid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.integrationCallId = value;
    this.dto.status = qs.status;
    this.dto.entryDate = qs.entrydate;
    this.dto.taskName = qs.taskname;
    this.dto.appSystemName = qs.appsystemname;
    this.dto.statusMessage = qs.statusmessage;
  }
}
