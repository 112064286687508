// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import DynamicFormSettingsResourceViewModel from '../../../../viewModel/resource/DynamicFormSettingsResourceViewModel';
import DynamicFormSettingsResourceDTO from '../../dto/resource/DynamicFormSettingsResourceDTO';

export default abstract class DynamicFormSettingsResourceModel extends BaseModel<DynamicFormSettingsResourceDTO> {
  /**
  */
  get mandatoryEmail() { return this.dto.mandatoryEmail; }
  set mandatoryEmail(value) { this.dto.mandatoryEmail = value; }
  /**
  */
  get mandatoryAddress() { return this.dto.mandatoryAddress; }
  set mandatoryAddress(value) { this.dto.mandatoryAddress = value; }
  /**
  */
  get hideAddress() { return this.dto.hideAddress; }
  set hideAddress(value) { this.dto.hideAddress = value; }
  /**
  */
  get hideCompanyName() { return this.dto.hideCompanyName; }
  set hideCompanyName(value) { this.dto.hideCompanyName = value; }
  /**
  */
  get hideDateOfBirth() { return this.dto.hideDateOfBirth; }
  set hideDateOfBirth(value) { this.dto.hideDateOfBirth = value; }
  /**
  */
  get hidePhoneNumber() { return this.dto.hidePhoneNumber; }
  set hidePhoneNumber(value) { this.dto.hidePhoneNumber = value; }
  /**
  */
  get hideMobilePhoneNumber() { return this.dto.hideMobilePhoneNumber; }
  set hideMobilePhoneNumber(value) { this.dto.hideMobilePhoneNumber = value; }
  /**
  */
  get hideHouseNumber() { return this.dto.hideHouseNumber; }
  set hideHouseNumber(value) { this.dto.hideHouseNumber = value; }
  /**
  */
  get hideAddressLine1() { return this.dto.hideAddressLine1; }
  set hideAddressLine1(value) { this.dto.hideAddressLine1 = value; }
  /**
  */
  get hidePOBox() { return this.dto.hidePOBox; }
  set hidePOBox(value) { this.dto.hidePOBox = value; }
  /**
  */
  get hideCountry() { return this.dto.hideCountry; }
  set hideCountry(value) { this.dto.hideCountry = value; }
  /**
  * @type {int32}
  */
  get maxWidthInPixels() { return this.dto.maxWidthInPixels; }
  set maxWidthInPixels(value) { this.dto.maxWidthInPixels = value; }
  /**
  * @type {int32}
  */
  get paddingInPixels() { return this.dto.paddingInPixels; }
  set paddingInPixels(value) { this.dto.paddingInPixels = value; }
  /**
  */
  get contactGroupTitle() { return this.dto.contactGroupTitle; }
  set contactGroupTitle(value) { this.dto.contactGroupTitle = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = qs.mandatoryEmail === 'true' ? true : qs.mandatoryemail;
    value = qs.mandatoryEmail === 'false' ? false : undefined;
    this.dto.mandatoryEmail = value;
    value = qs.mandatoryAddress === 'true' ? true : qs.mandatoryaddress;
    value = qs.mandatoryAddress === 'false' ? false : undefined;
    this.dto.mandatoryAddress = value;
    value = qs.hideAddress === 'true' ? true : qs.hideaddress;
    value = qs.hideAddress === 'false' ? false : undefined;
    this.dto.hideAddress = value;
    value = qs.hideCompanyName === 'true' ? true : qs.hidecompanyname;
    value = qs.hideCompanyName === 'false' ? false : undefined;
    this.dto.hideCompanyName = value;
    value = qs.hideDateOfBirth === 'true' ? true : qs.hidedateofbirth;
    value = qs.hideDateOfBirth === 'false' ? false : undefined;
    this.dto.hideDateOfBirth = value;
    value = qs.hidePhoneNumber === 'true' ? true : qs.hidephonenumber;
    value = qs.hidePhoneNumber === 'false' ? false : undefined;
    this.dto.hidePhoneNumber = value;
    value = qs.hideMobilePhoneNumber === 'true' ? true : qs.hidemobilephonenumber;
    value = qs.hideMobilePhoneNumber === 'false' ? false : undefined;
    this.dto.hideMobilePhoneNumber = value;
    value = qs.hideHouseNumber === 'true' ? true : qs.hidehousenumber;
    value = qs.hideHouseNumber === 'false' ? false : undefined;
    this.dto.hideHouseNumber = value;
    value = qs.hideAddressLine1 === 'true' ? true : qs.hideaddressline1;
    value = qs.hideAddressLine1 === 'false' ? false : undefined;
    this.dto.hideAddressLine1 = value;
    value = qs.hidePOBox === 'true' ? true : qs.hidepobox;
    value = qs.hidePOBox === 'false' ? false : undefined;
    this.dto.hidePOBox = value;
    value = qs.hideCountry === 'true' ? true : qs.hidecountry;
    value = qs.hideCountry === 'false' ? false : undefined;
    this.dto.hideCountry = value;
    value = Number.parseFloat(qs.maxwidthinpixels);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.maxWidthInPixels = value;
    value = Number.parseFloat(qs.paddinginpixels);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.paddingInPixels = value;
    this.dto.contactGroupTitle = qs.contactgrouptitle;
  }
}
